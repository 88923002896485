import * as React from 'react'
import { FaRegDotCircle } from 'react-icons/fa'
import './Sinclair.scss'

const Sinclair: React.FC = () => {
  const [values, setValues] = React.useState({total: 0, bodyweight: 0, isMale: true})
  const [answer, setAnswer] = React.useState<number>(0)

  function SinclairFormula(total: any, bodyweight: any, isMale?: boolean) {
    let output
    
    if(isMale === true) {
        output = total*(10**(0.751945030*((Math.log10(bodyweight/175.508))**2)))
    } else {
        output = total*(10**(0.783497476*((Math.log10(bodyweight/153.655))**2)))
    } return setAnswer(output)

  }

  const changeHandler = (e: any) => {
    setValues({...values, [e.target.name]: e.target.value})
  }

  return (
    <div className='form-container'>
    <div className='form-content'>
      <form className='form'>
        <h2 className='sin__header'>Calcula tu coeficiente actual</h2>
        <div>
        <p>PARA COMPARAR Y CLASIFICAR LOS RESULTADOS, ESPECIALMENTE ENTRE LAS CATEGORÍAS 
          DE PESO CORPORAL, LA FEDERACIÓN INTERNACIONAL DE HALTEROFILIA UTILIZA LOS 
          COEFICIENTES DE SINCLAIR QUE SE DERIVAN ESTADÍSTICAMENTE Y SE CALCULAN PARA 
          UN CICLO OLÍMPICO (DURANTE CUATRO AÑOS, A PARTIR DE LA PRIMAVERA DE CADA AÑO OLÍMPICO).</p>
      </div>
        <div className={values.isMale ? 'sinclair__check' : 'sinclair__active'} onClick={() => setValues({...values, isMale: true})}><FaRegDotCircle className='dot_tar'/><p>Hombre</p></div>
        <div className={!values.isMale ? 'sinclair__check' : 'sinclair__active'}  onClick={() => setValues({...values, isMale: false})}><FaRegDotCircle className='dot_tar'/><p>Mujer</p></div>
        <div className='form-group'>
          <label htmlFor='name'>
            Total *
          </label>
          <input type='number' name='total' id="total" className='sinclair__input' onChange={changeHandler}/>
        </div>
        <div className='form-group'>
          <label htmlFor='name'>
            Peso Corporal *
          </label>
          <input type='number' name='bodyweight' id="bodyweight" className='sinclair__input' onChange={changeHandler}/>
        </div>
        <input type='button' value='Calcular' className='sinclair__button' onClick={() => {SinclairFormula(values.total, values.bodyweight, values.isMale)}} disabled={values.bodyweight && values.total !== 0 ? false : true} />
        <p>* CAMPO OBLIGATORIO</p>
        <p className='sinclair__total'>Sinclair total : {answer.toString().slice(0, 5)}</p>
      </form>
    </div>
    </div>
  )
}

export default Sinclair