import * as React from 'react'
import './Tariffs.scss'
import { MdDone } from 'react-icons/md'
const Fade: any = require("react-reveal/Fade")

const TarriffCard = () => {
  return (
    <div className='tariffs__one'>
      <div className='t-one__header'>
        <h3>Beyound the limits</h3>
      </div>
      <h1>
        89.99$
        <p>Per month</p>
      </h1>
      <div className='content__div-tar'>
        <ul>
          <li><MdDone className='md__done'/> Some info</li>
          <li><MdDone className='md__done'/> Some info</li>
          <li><MdDone className='md__done'/> Some info</li>
          <li><MdDone className='md__done'/> Some info</li>
          <li><MdDone className='md__done'/> Some info</li>
        </ul>
      </div>
      <input type='button' value='Consultar el precio' className='tariff__button'
        onClick={() => global.location.href = 'mailto:halterofilialostercios@gmail.com'} />
    </div>
  )
}

const Tariffs: React.FC = () => {

  return (
    <div style={{ background: `var(--primary)`}}>
    <Fade cascade>
    <div className='tariffs'>
      <div  className='feo_p'>
        <h2>Let's get started!</h2>
        <p className='tariffs__par'>elige el plan que más te convenga y contáctanos mencionando todos los detalles.</p>
      </div>
      <div className='tarrifs__holder'>
        <TarriffCard />
        <TarriffCard />
      </div>
    </div>
    </Fade>
    </div>
  )
}

export default Tariffs