import toni from '../../static/img/toni_main.webp'
import './CoachBlock.scss'

const CoachBlock: React.FC = () => {
  return (
    <div className='coach__block-holder'>
      <div className='sup'>
        <img src={toni} className='coach-img' alt='Toni Torres'/>
      </div>
    </div>
  )
}

export default CoachBlock