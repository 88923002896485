import React, { FC, useState } from 'react'
import axios from 'axios'
import './ContactForm.scss'
const Fade: any = require("react-reveal/Fade")

const ContactForm: FC = () => {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [consultation, setConsultation] = useState('')
  const [phone, setPhone] = useState('')
  const [showModal, setShowModal] = useState(false)

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    try {
      const response = await axios.post('https://formspree.io/f/mwkdderd', { 
        name,
        email,
        consultation,
        phone
       })
      console.log('Form submission successful:', response.data)
      setName('')
      setEmail('')
      setPhone('')
      setConsultation('')
      setShowModal(true)

      setTimeout(() => {
        setShowModal(false)
      }, 5000)
    } catch (error) {
      console.error('Form submission failed:', error)
    }
  }

  return (
    <div className='form-container' id='contact'>
      <Fade cascade>
      <div className='form-content'>
        <h2>Contacta con nosotros</h2>
        <p>Información general</p>
        <div className='address'>
          <p>
            <span>C. MIGUEL YUSTE, 43</span>
            <span>28902 MADRID</span>
            <span>España</span>
          </p>
          <p>
            <span>HALTEROFILIALOSTERCIOS@GMAIL.COM</span>
            <span>(514) 467-9780</span>
          </p>
        </div>
        <p>Para todas las consultas, por favor utiliza el formulario a continuación</p>
        <form onSubmit={handleSubmit} className='form'>
          <div className='form-group'>
            <label htmlFor='name'>
              Nombre *
            </label>
            <input 
              type='text' 
              id='name' 
              value={name}
              onChange={(e) => setName(e.target.value)}
              className='sinclair__input' 
            />
          </div>
          <div className='form-group'>
            <label htmlFor='email'>
              Email *
            </label>
            <input 
              type='email' 
              id='email' 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className='sinclair__input' 
            />
          </div>
          <div className='form-group'>
            <label htmlFor='phone'>
              Número de teléfono *
            </label>
            <input 
              type='string' 
              id='phone' 
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className='sinclair__input' 
            />
          </div>
          <div className='form-group'>
            <label htmlFor='consultation'>
              Consulta *
            </label>
            <textarea 
              id='consultation' 
              value={consultation}
              onChange={(e) => setConsultation(e.target.value)}
              className='sinclair__input' 
              rows={4}>  
            </textarea>
          </div>
          <p>
            Al hacer clic en 'Enviar', reconoces que almacenaremos de forma segura y procesaremos 
            los datos personales proporcionados anteriormente.
          </p>
          <button type='submit' className='sinclair__button' disabled={
            name && email && consultation && phone !== '' ? false : true
          }>
            Enviar
          </button>
          <p>* CAMPO OBLIGATORIO</p>
        </form>
      </div>
      </Fade>
    </div>
  )
}

export default ContactForm