import * as React from 'react'
import './ClassTable.scss'
const Fade: any = require("react-reveal/Fade")

const ClassTable: React.FC = () => {

  return (
    <div className='class__table_two'>
      <Fade cascade>
        <div className='class__table-section-two'>
          <h1>Clases de Halterofilia</h1>
          <p className='class__table-p'>Las sesiones de halterofilia generalmente involucran una variedad de ejercicios que se enfocan en diferentes grupos musculares. Estos pueden incluir ejercicios como sentadillas, peso muerto, press militar, etc. Estos ejercicios se suelen realizar con barras, mancuernas o máquinas.</p>
          <p className='class__table-p'>Durante una sesión, un levantador generalmente realizará un calentamiento, seguido de una serie de ejercicios con mayor peso y resistencia. El levantador también se centrará en la forma y la técnica adecuadas para maximizar la eficacia de los ejercicios y minimizar el riesgo de lesiones. Dependiendo de sus objetivos, las sesiones también pueden incluir ejercicios para la estabilidad central, la flexibilidad y el equilibrio.</p>
          <p className='class__table-p'>Además, una sesión también puede incluir ejercicios pliométricos, cardio y estiramiento para completar el entrenamiento. Una clase típica de halterofilia puede durar entre 60 y 90 minutos, y se recomienda tener un tiempo adecuado de descanso y recuperación entre sesiones.</p>
        </div>
      </Fade>  
    </div>
  )
}

export default ClassTable