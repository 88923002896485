import * as React from 'react'
import Scroller from '../Scroller/Scroller'
import Card from '../Cards/Card/Card'
import CardFree from '../Cards/CardFree/CardFree'
import './Opinions.scss';
const Fade: any = require("react-reveal/Fade")

const Opinions: React.FC = () => {

  return (
    <div className='opinions__holder'>
      <p className='slider-left'>
        BIENVENUE WELCOME ようこそ BIENVENIDO <span>Ласкаво просимо</span>{' '}
        BIENVENUE WELCOME ようこそ BIENVENIDO <span>Ласкаво просимо</span>{' '}
        BIENVENUE WELCOME ようこそ BIENVENIDO <span>Ласкаво просимо</span>{' '}
        BIENVENUE WELCOME ようこそ BIENVENIDO <span>Ласкаво просимо</span>
      </p>
      <div className='opinions' id='opinions'>
        <Fade duration={500} bottom cascade>
        <div className='op__headers'>
          <h1 className='op__header__text'>¿Qué dicen de nosotros?</h1>
          <p className='op__header__p'>
            Opiniones recientes basadas en Google Opinions.
          </p>
        </div>
        </Fade>
        <div className='op__cards__holder'>
          <div className='op__cards'>
            <CardFree
              img='https://lh3.googleusercontent.com/a-/AOh14Gjc_5E8O7TP46hPM5luG76S6azjkfcqwOVlTxDT0Q=w75-h75-p-rp-mo-br100'
              name='Victor Siankope'
              rating={5}
              text='El mejor lugar donde aprender halterofilia en Madrid para todas las edades. Además dispone de un club de competidores donde también empezar a entrar en el mundo de la competición. Puedes ir y solicitar tu clase de prueba gratuita!!!'
            />
            <Fade right duration={1500} cascade>
            <Scroller 
              isNegative={true}
              rotate={0}
              className='op__grouped'
              offset={0.14}
            >
              <div className='hidden__card'>
                <Card
                  img='https://lh3.googleusercontent.com/a-/AOh14Gjc_5E8O7TP46hPM5luG76S6azjkfcqwOVlTxDT0Q=w75-h75-p-rp-mo-br100'
                  name='Victor Siankope'
                  rating={5}
                  text='El mejor lugar donde aprender halterofilia en Madrid para todas las edades. Además dispone de un club de competidores donde también empezar a entrar en el mundo de la competición. Puedes ir y solicitar tu clase de prueba gratuita!!!'
                />
              </div>

              <Card
                img='https://lh3.googleusercontent.com/a-/ALV-UjV8TF6s8OqVJzkcgrJjUmkEiFXdfM5XeKZRrcwmwv20OQ=s40-c-rp-mo-br100'
                name='Pedro Cortés'
                rating={5}
                text='Gran club! Buen ambiente e increíble para desarrollarte deportiva y personalmente!
                Toni es un gran entrenador y el gimnasio equipado para entrenar sin problema.'
              />
              <Card
              img='https://lh3.googleusercontent.com/a-/ALV-UjWX0VbBud8_G1z0uprpZj_LM_lFBo6yBIo3W8SY-KSfjBke=s40-c-rp-mo-br100'
              name='Paula'
              rating={5}
              text='Si quieres aprender halterofilia, este es tu club. Buen ambiente y buen material.'
              />
            </Scroller>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Opinions;