import * as React from 'react'
import { GiStrongMan } from 'react-icons/gi'
import Scroller from '../Scroller/Scroller'
import { IoMdMan } from 'react-icons/io'
import { GiWeightLiftingUp } from 'react-icons/gi'
import cardFirst from '../../static/img/rsz_1katti__recover-min_.jpg'
import cardSecond from '../../static/img/varo.jpg'
import './Info.scss'
const Fade: any = require("react-reveal/Fade")

const Info: React.FC = () => {

  return (
    <div className='info__holder' id='aboutUS'>
      <div className='info'>
        <div className='info__states'>
          <Fade cascade>
          <div className='info__state'>
            <IoMdMan className='state__icon' />
            <h3>Mejora de la capacidad de concentración</h3>
            <p>
              Mejorar la concentración es esencial para el rendimiento 
              académico y laboral.
            </p>
          </div>
          <div className='info__state'>
            <GiWeightLiftingUp className='state__icon' />
            <h3>Aprendizaje y perfeccionamiento técnico</h3>
            <p>
              Aprendizaje técnico es clave para el éxito laboral. Perfección 
              técnica requiere dedicación y constancia.
            </p>
          </div>
          <div className='info__state'>
            <GiStrongMan className='state__icon' />
            <h3>Mejora de potencia y fuerza muscular</h3>
            <p>
              La fuerza y potencia muscular son fundamentales 
              para el rendimiento físico y deportivo.
            </p>
          </div>
          </Fade>
        </div>
        <div className='block__wrapper'>
        <div className='info__block'>
            <Scroller isNegative={false} offset={0.04} rotate={-3}>
            <img
              className='block__img__one'
              src={cardFirst}
              alt='katti__img'
            ></img>
            </Scroller>
            <Scroller isNegative={true} offset={0.06} rotate={3}>
            <img
              className='block__img__two'
              src={cardSecond}
              alt='oleh__img'
            ></img>
            </Scroller>
        </div>
          <Fade>
          <div className='info__par'>
            <h2>
              ¿Quiénes somos y por qué deberías probar halterofilia?
            </h2>
            <p>
              Nuestro club de halterofilia nace con la firme convicción de hacer 
              las cosas en base a lo tradicional sin perder de vista el avance y 
              los progresos. Nos gusta decir que hacemos halterofilia de toda la vida. 
              Y además también participamos en novedosos estudios y nos apoyamos en la 
              ciencia para evolucionar en una búsqueda constante de la perfección. Halterofilia 
              para todos, nos da igual tú nivel, todo el mundo encaja en nuestro club desde los más 
              experimentados competidores hasta los que quieren empezar de 0. Desde los niños haciendo 
              técnica hasta los máster más longevos. Todos con una misma ilusión levantar peso.
            </p>
          </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default Info