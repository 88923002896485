import Footer from '../components/Footer/Footer'
import Navbar from '../components/Navbar/Navbar'
import SmoothScroll from '../components/SmoothScrolling/SmoothScrolling'
import SliderCalculate from '../components/SliderCalculate/SliderCalculate'
import Sinclair from '../components/Sinclair/Sinclair'

const Classes: React.FC = () => {
  return (
    <div style={{ background: `var(--primary)` }}>
      <Navbar/>
        <Sinclair />
        <Footer />
    </div>
  )
}

export default Classes