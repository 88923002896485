import * as React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../static/img/JerkLogo.webp'
import { AiFillInstagram } from 'react-icons/ai'
import { FaCalculator } from "react-icons/fa"
import Navbarmenu from '../Navbarmenu/Navbarmenu'
import './Navbar.scss'
const Fade: any = require("react-reveal/Fade")

const Navbar: React.FC = () => {

  const [navbar, setNavbar] = React.useState<boolean>(false)

  const showColor = () => {
    if (window.scrollY >= 100) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  window.addEventListener('scroll', showColor)

  return (
    <Fade top >
      <div
        className={
            navbar ? 'luc__nav active__nav fixed-top' : 'luc__nav fixed-top'
        }
        >
        <Link to='/'>
          <img src={logo} className='logo__navbar' />
        </Link>
        {navbar && window.location.pathname=="/" && window.innerWidth > 1000 ?
        <div className='middle__nav'>
          <p onClick={() => {
              if (typeof window != 'undefined') {
                var title = window.document.getElementById('opinions')

                if (title != null) {
                  title.scrollIntoView({behavior:'smooth'})
                }
              }
            }}>Opiniones</p>
          <p  onClick={() => {
              if (typeof window != 'undefined') {
                var title = window.document.getElementById('aboutUS')

                if (title != null) {
                  title.scrollIntoView({behavior:'smooth'})
                }
              }
            }}>Quienes Somos</p>
          <p  onClick={() => {
              if (typeof window != 'undefined') {
                var title = window.document.getElementById('classes')

                if (title != null) {
                  title.scrollIntoView({behavior:'smooth'})
                }
              }
            }}>Clases</p>
          <p  onClick={() => {
              if (typeof window != 'undefined') {
                var title = window.document.getElementById('contact')

                if (title != null) {
                  title.scrollIntoView({behavior:'smooth'})
                }
              }
            }}>Contact</p>
        </div> : <></> }
        <div className='luc__nav__icons'>

          <a href='https://www.instagram.com/halterofilialostercios/?igshid=YmMyMTA2M2Y%3D' target='_blank'>
            <AiFillInstagram className='icon__small' />
          </a>
          <a href='/calculator' className='menu__grand'>
            {/* <Navbarmenu /> */}
            <FaCalculator className='calc__icon' />
          </a>
        </div>
    </div>
    </Fade>
  )
}

export default Navbar