import * as React from 'react'
import Scroller from '../Scroller/Scroller'
import CardSlider from '../Cards/CardSlider/CardSlider'
import './SemiCards.scss'
const Fade: any = require("react-reveal/Fade")

const SemiCards: React.FC = () => {

  return (
      <div className='semi'>
        {/* <Fade bottom cascade>
        <div className='hor__brake-holder'>
          <h1>Nuestros Valores</h1>
          <p>
            El Compromiso que Nos Impulsa.
          </p>
        </div>
        </Fade> */}
        <div className='background__text'>
          <div className='card__slider-holder'>
            <Scroller isNegative={true} offset={0.04} rotate={0}>
            <CardSlider
              style={{
                position: `relative`,
                top: `200px`,
              }}
              title='CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA
              CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA'
              imgSrc='https://res.cloudinary.com/yevhenbk/image/upload/v1700604998/photo_2023-11-21_23-15-32_por76h.jpg'
              text='CONSTANCIA'
            />
            </Scroller>
            <Scroller isNegative={true} offset={0.14} rotate={0}>
            <CardSlider
              style={{
                position: `relative`,
                top: `200px`,
                right: `100px`,
              }}
              title='Determinación Determinación Determinación Determinación Determinación Determinación Determinación Determinación Determinación
              Determinación Determinación Determinación Determinación Determinación Determinación Determinación Determinación Determinación'
              imgSrc='https://res.cloudinary.com/yevhenbk/image/upload/v1664722004/katti__focused_gd1kcb.jpg'
              text='Determinación'
            />
            </Scroller>
            <Scroller isNegative={true} offset={0.18} rotate={0}>
            <CardSlider
              style={{
                position: `relative`,
                top: `1000px`,
                left: `100px`,
              }}
              title='Apoyo Apoyo Apoyo Apoyo Apoyo Apoyo Apoyo Apoyo Apoyo
              Apoyo Apoyo Apoyo Apoyo Apoyo Apoyo Apoyo Apoyo Apoyo'
              imgSrc='https://res.cloudinary.com/yevhenbk/image/upload/v1700606804/photo_2023-11-21_23-34-47_vtp75y.jpg'
              text='Apoyo'
            />
            </Scroller>
            <CardSlider
              title='Disciplina Disciplina Disciplina Disciplina Disciplina Disciplina Disciplina Disciplina Disciplina
              Disciplina Disciplina Disciplina Disciplina Disciplina Disciplina Disciplina Disciplina Disciplina'
              imgSrc='https://res.cloudinary.com/yevhenbk/image/upload/v1700606251/photo_2023-11-21_23-37-12_fngzo7.jpg'
              text='Disciplina'
            />
          </div>
          <div className='card__slider-second-holder'>
            <CardSlider
              title='CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA
              CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA CONSTANCIA'
              imgSrc='https://res.cloudinary.com/yevhenbk/image/upload/v1700604998/photo_2023-11-21_23-15-32_por76h.jpg'
              text='CONSTANCIA'
            />
            <CardSlider
              title='Determinación Determinación Determinación Determinación Determinación Determinación Determinación Determinación Determinación
              Determinación Determinación Determinación Determinación Determinación Determinación Determinación Determinación Determinación'
              imgSrc='https://res.cloudinary.com/yevhenbk/image/upload/v1664722004/katti__focused_gd1kcb.jpg'
              text='Determinación'
            />
            <CardSlider
              title='Apoyo Apoyo Apoyo Apoyo Apoyo Apoyo Apoyo Apoyo Apoyo
              Apoyo Apoyo Apoyo Apoyo Apoyo Apoyo Apoyo Apoyo Apoyo'
              imgSrc='https://res.cloudinary.com/yevhenbk/image/upload/v1700606804/photo_2023-11-21_23-34-47_vtp75y.jpg'
              text='Apoyo'
            />
            <CardSlider
              title='Disciplina Disciplina Disciplina Disciplina Disciplina Disciplina Disciplina Disciplina Disciplina
            Disciplina Disciplina Disciplina Disciplina Disciplina Disciplina Disciplina Disciplina Disciplina'
              imgSrc='https://res.cloudinary.com/yevhenbk/image/upload/v1700606251/photo_2023-11-21_23-37-12_fngzo7.jpg'
              text='Disciplina'
            />
          </div>
        </div>
      </div>
  )
}

export default SemiCards